import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { TextField, FormGroup, FormControl, InputAdornment, IconButton } from '@mui/material';
import { Card, CardContent, Box, CircularProgress } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CardActions, CardHeader } from '@mui/material';
import logo from '../assets/lyncdefault.svg';
import axios from 'axios';
import Button from '../components/button';
import CloseIcon from '../components/icons/closeIcon';


  const useStyles = makeStyles(() => ({
      root: {
        "& .MuiFormLabel-root":{
            display:'inline-flex',
           alignItems:'center'
        },
        "& .MuiFormHelperText-root" :{
            fontSize: '11px',
            fontWeight: 400,
            lineHeight: '11.2px',
        },
      },
      title: {
        "& .MuiTypography-h5": {
            fontFamily: 'sans-serif !important',
            fontWeight: 500,
            fontSize: '1.2rem',
            color: '#000',
        },
      },
      actionsCard:{
        "& .MuiCardActions-root":{
            padding:'16px !important',
        },
      },
    }));


const Login = (props) => {
    const {setIsLoading, setIsLogin} = props;
    const {root, title, actionsCard} = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let navigate = useNavigate();
    useEffect(() => {
        function hasJWT() {
            let flag = false;
    
            //check user has JWT token
            localStorage.getItem("token") ? flag=true : flag=false
            return flag
        }
        if (hasJWT()) {
            //   return <Navigate to="/" />;
                navigate("/analytics/users");
            }
    },[])

    const emailHandler = (event) =>{
        setEmail(event.target.value);
    }

    const passwordHandler = (event) =>{
        setPassword(event.target.value);
        setValidPassword(false);
    }


    const validateEmail = (e) => {
        if (!email.includes('@')) {
            setValidEmail(true);
        } else {
            setValidEmail(false); 
        }
    }

    const validatePassword = (e) => {
        if(!password.length>0){
            setValidPassword(true);
            setErrorMessage("Password is required")
        }   
        else{
            setValidPassword(false);
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    async function loginHandle(event) {
        event.preventDefault();
        console.log(email)
        if (email.length===0 && password.length===0){
            setValidEmail(true);
            setValidPassword(true);
            setErrorMessage("Password is required")
        }
        else if(email.length===0 && password.length>0){
            setValidEmail(true);
        }
        else if(password.length===0 && email.length>0){
            setValidPassword(true);
            setErrorMessage("Password is required")
        }
        else if(email.length!==0 && password.length!==0){
            setIsLoading(true);
            let data = {
                "email" : email,
                "password" : password
            };  
            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/site/login`,
            data : data
            };
            axios(config)
            .then((res) => {
                setIsLoading(false);
                    localStorage.setItem("token", res.data.data.token);
                    setIsLogin(true);
                    navigate("/analytics/users");
                
            })
            .catch((error) => {
                setIsLoading(false);
                setFormValid(true);
                console.error(error)
            })
        }
        
    }


    return(
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                minWidth="100%"
                backgroundColor="#20123a"
                // margin="auto 40%"
            >
                <Card 
                    sx={{ 
                        width:'500px', 
                        padding:'50px', 
                    }}
                >
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <CardHeader
                        className={title}
                        // component={Typography}
                        title={"Login"}
                        // subheader={"Login"}
                    />
                    <form onSubmit={loginHandle}>
                        <CardContent>
                                <FormControl sx={{ gap: 2, width:'100%' }} variant="outlined">
                                {formValid && 
                                        <div className="p-1 justify-content-between rounded" style={{display:'flex', backgroundColor:'#e66', color:'#FFF', width:'100%', flexDirection:'row'}}>
                                            <p className="m-0" style={{paddingLeft:'2%', fontSize:'0.95rem'}}>Incorrect credentials</p>
                                            <CloseIcon sx={{marginTop: '5px' ,cursor:'pointer'}} onClick={()=>setFormValid(false)}/>
                                        </div>
                                        }
                                        <FormGroup sx={{ gap: 1 }}>
                                            <TextField 
                                                className={ root }
                                                variant="outlined"
                                                label={
                                                    <Fragment>
                                                        <AccountCircle fontSize="small" /> Email
                                                    
                                                    </Fragment>
                                                }
                                                onChange={emailHandler}
                                                value={email}
                                                // required={true}
                                                onBlur={validateEmail}
                                                error={validEmail}
                                                helperText={validEmail ? 'Please enter a valid Email' : ' '}
                                            />

                                            <TextField
                                                className={ root }
                                                label={
                                                    <Fragment>
                                                        <LockIcon fontSize="small"/>
                                                        Password
                                                    </Fragment>
                                                }
                                                variant="outlined"
                                                type={showPassword ? "text" : "password"} 
                                                value={password}
                                                onChange={passwordHandler}
                                                onBlur={validatePassword}
                                                error={validPassword}
                                                helperText={validPassword ? errorMessage : ' '}
                                                // required={true}
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                        </FormGroup> 
                                </FormControl>
                        </CardContent>
                        <CardActions className={actionsCard}>
                            <div className="col-12">
                                <Button text={'Login'} type="submit" className={"primary-button-violet w-100"} />   
                            </div>
                        </CardActions>
                    </form>
                </Card>
            </Box>
        
       
    )
}

export default Login;