import React, { useEffect, useState, useRef, Fragment } from "react";
import axios from "axios";
import "./users.css";
import {
  MenuItem,
  FormControl,
  Typography,
  TextField,
  Menu,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import { useStyles } from '../components/commonStyles/fontColor'
import { useTheme } from "@mui/styles";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import BackButton from "../components/back";
import format from "date-fns/format";
import CalenderIcon from "../components/icons/calenderIcon";
import { DateRange } from "react-date-range";
// import Wrapper from "../components/card";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);
const config = {
  type: "line",
  responsive: true,
  scales: {
    yAxes: {
      title: {
        display: true,
        text: "Number of users added",
      },
    },
    xAxes: {
      grid: {
        display: false,
      },
    },
  },
  interaction: {
    mode: "nearest",
    // axis: 'x',
    intersect: false,
  },
  animation: {
    duration: 2000,
  },
  plugins: {
    legend: {
      position: "bottom",
    },
    // title: {
    //   display: true,
    //   text:'users per day',
    // }
  },
};

const User = (props) => {
  const { setIsLoading } = props;
  const theme = useTheme();
  // const [isLoading, setIsLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState("");
  const [dataType, setDataType] = useState([]);
  // const [datee, setDatee] = useState([]);
  const [max, setMax] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [datePickEl, setDatePickEl] = useState(null);
  const datePick = Boolean(datePickEl);

  let newDate = new Date();
  // let date_raw = newDate.getDate();
  // let month_raw = newDate.getMonth() + 1;
  // let year = newDate.getFullYear();
  let myPastDate = new Date(newDate);
  myPastDate.setDate(myPastDate.getDate() - 14);
  const [data, setData] = useState({
    labels: "",
    datasets: [{}],
  });
  // date state
  const [range, setRange] = useState([
    {
      startDate: myPastDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);
  async function getBranchList() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/branch/list`,
      headers: {
        "Content-Type": "application/json",
        },
    };
    axios(config)
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function getDataByType() {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/user/user-count-by-type`,
      headers: {
        "Content-Type": "application/json",
        },
      params: {
        branch_id: branchSelected,
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setDataType(res.data.data);
        setMax(res.data.data.branches[0].total_count);
        setBranchSelected(res.data.data.branches[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function getFilterdate() {
    // const labelSet = []
    const dataSet1 = [];
    const dataSet2 = [];
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/user/user-count-by-date?`,
      headers: {
        "Content-Type": "application/json",
        },
      params: {
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        for (const val of res.data.data) {
          
          dataSet1.push(moment(val.date).format("MMM-DD"));
          dataSet2.push(val.count);
        }
        // dataSet1.push(datee);
        // dataSet2.push("0");
        setData({
          labels: dataSet1,
          datasets: [
            {
              label: "users",
              data: dataSet2,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235)",
              pointRadius: 4,
              pointHoverRadius: 10,
              tension: 0,
            },
          ],
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getBranchList();
    getDataByType();
    getFilterdate();
    // setDatee(date_raw + "/" + month_raw + "/" + year);

    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, [range, branchSelected]);

  const handleChange = (event) => {
    setBranchSelected(event.target.value);
    //api call for branchwise data
  };
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    setAnchorEl(null);

    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };
  const datePickerOpen = (event) => {
    setDatePickEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setDatePickEl(null);
  };
  const onChangeClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
        <div className="container pb-5 pt-2">
          <div className="col-12 row header mt-4">
            <div className="col-12 col-md-1"><BackButton /></div>
          <div className="col-12 col-md-11 d-flex justify-content-end">
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 257 }}>
              <TextField id="demo-simple-select-standard" value={branchSelected} select sx={{ width: "100%" }} onChange={handleChange} label="Branch name">
                {branches && branches.map((object) => ( <MenuItem value={object.id} key={object.id}>{object.name}</MenuItem> ))}
              </TextField>
            </FormControl>
          </div>
        </div>
        <div className="mt-3 mb-3" style={{ display: "flex", width: "100%", height: "63px" }}>
          {/* <div className="containerLync col-md-2">
            <Typography sx={{ color: theme.palette.gray.gray_2 }}>Total Users</Typography>
            <Typography variant="number" sx={{ color: theme.palette.lync_black.main }}>{dataType.totalUsers}</Typography>
          </div> */}
          <div className="containerLync">
            <Typography sx={{ color: theme.palette.gray.gray_2 }}>{dataType.branches && dataType.branches[0].name}</Typography>
            <Typography variant="number" sx={{ color: theme.palette.lync_black.main }}>{dataType.branches && dataType.branches[0].total_count}</Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "219px", width: "100%", gap: "30px" }} >
          {dataType.branches && dataType.branches[0].userCount.map((object,id) => {
            let background = "";
            let width = (object.user_count / max) * 100;
            if (object.user_type === "teacher") {
              background = theme.palette.red_error.main;
            } else if (object.user_type === "student") {
              background = theme.palette.yellow_warning.main;
            } else if (object.user_type === "parent") {
              background = theme.palette.blue_default.main;
            }
            return (
              <div key={id} style={{ display: "flex", height: "53px", alignItems: "flex-end"}}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }} className="col-md-2" >
                  <Typography sx={{ color: theme.palette.gray.gray_2, textTransform: "capitalize !important", }} > {object.user_type} </Typography>
                  <div style={{ display: "flex", gap: "13px" }}>
                    <span style={{ height: "25px", width: "25px", background: background, borderRadius: "2.5px", }} ></span>
                    <Typography variant="progress" sx={{ color: theme.palette.lync_black.main }} > {object.user_count} </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", height: "25px", order: 1, }} className="col" >
                  <span style={{ background: background, width: width + "%", borderRadius: "2.5px"}} ></span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-5" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
        <div className="header row mt-5">
          <div className="col-12 col-md-3">
            <Typography sx={{ color: theme.palette.lync_black.main, fontWeight: "700 !important"}} > Users per day </Typography>
          </div>
          <div className="col-12 col-md-9 d-flex justify-content-end">
          <TextField
            sx={{ width: "40%" }}
            fullWidth
            id="daterange"
            InputProps={{startAdornment: (<InputAdornment position="start"><IconButton><CalenderIcon /></IconButton></InputAdornment> ),}}
            value={`${format(
              range[0].startDate,
              "MMM dd, yyyy"
            )} - ${format(range[0].endDate, "MMM dd, yyyy")}`}
            readOnly
            className="inputBox"
            onClick={datePickerOpen}
          ></TextField>
          <Menu id="settings-menu" anchorEl={datePickEl} open={datePick} onClose={datePickerClose} MenuListProps={{ "aria-labelledby": "settings-button" }}>
            <DateRange onChange={(item) => setRange([item.selection])} editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={range} months={1} direction="horizontal" className="calendarElement"/>
          </Menu>
          </div>
        </div>
        <div className="mt-5">
          <div className='col-md-7'>
            {data && <Line data={data} options={config}></Line>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default User;
