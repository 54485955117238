import React, { useState, useEffect } from "react";
import moment from "moment";
import BackButton from "../components/back";
import Wrapper from "../components/card";
import { Button } from "@mui/material";
// import moduleIcon from '.././assets/ico_module_dark.png';
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ActiveModule from "../components/icons/activeModule";
import InactiveModule from "../components/icons/inactiveModule";
import ModuleDark from "../components/icons/moduleDark";
import ErrorModule from "../components/icons/errorModule";
import AccessCount from "../components/icons/accessCount";
import ErrorCount from "../components/icons/errorCount";
import { useTheme } from "@mui/styles";
import {
  Typography,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import CalenderIcon from "../components/icons/calenderIcon";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { Fragment } from "react-is";
// import { LegendToggleTwoTone } from "@mui/icons-material";

const ModuleAccess = (props) => {
  const { setIsLoading } = props;
  const theme = useTheme();

  const [branches, setBranches] = useState([]);
  const [branchSelect, setBranchSelect] = useState("");
  const [active, setActive] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [errorModule, setErrorModule] = useState([]);
  const [totalError, setTotalError] = useState();
  const [totalInactive, setTotalInactive] = useState();
  const [totalActive, setTotalActive] = useState();
  const [moduleAccess, setModuleAccess] = useState([]);
  let countActive = [];
  let countInactive = [];
  let countError = [];


  let newDate = new Date();
  let myPastDate = new Date(newDate);
  myPastDate.setDate(myPastDate.getDate() - 14);
  const [range, setRange] = useState([
    {
      startDate: myPastDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [datePickEl, setDatePickEl] = useState(null);
  const datePick = Boolean(datePickEl);

  async function getDataByBranch() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/branch/list`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzI2MzQyNDQsImV4cCI6MTY3MjcyMDY0NCwidWlkIjoxMH0.LB9It-W1Q3EdLfUP7DnijYJWsB498s_Ev4GKOXsuZCA`,
      },
    };
    axios(config)
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function getActiveModule() {

    // setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/module/active-modules?limit=3`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        branchId: branchSelect,
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setActive(res.data.data);
        res.data.data.map((act) => {
          countActive.push(act.count);
          let max = 0;

          for (let i = 0; i < countActive.length; i++) {
            max = parseInt(max) + parseInt(countActive[i]);
          }
          setTotalActive(max);
        });
      })
      .catch((error) => {
        // setIsLoading(false);
        console.error(error);
      });
  }
  async function getInactiveModule() {
    // setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/module/inactive-modules?limit=3`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        branchId: branchSelect,
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setInactive([...res.data.data]);
        res.data.data.map((ina) => {
          countInactive.push(ina.count);
          let max = 0;

          for (let i = 0; i < countInactive.length; i++) {
            max = parseInt(max) + parseInt(countInactive[i]);
          }
          setTotalInactive(max);
        });
      })
      .catch((error) => {
        // setIsLoading(false);
        console.error(error);
      });
  }
  async function getErrorModule() {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/module/error-login-modules?limit=3`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        branchId: branchSelect,
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setErrorModule(res.data.data);
        res.data.data.map((err) => {
          countError.push(err.count);
          let max = 0;

          for (let i = 0; i < countError.length; i++) {
            max = parseInt(max) + parseInt(countError[i]);
          }
          setTotalError(max);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getModuleAccessCount() {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/module/module-access-count?`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        branchId: branchSelect,
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setModuleAccess(res.data.data);
      })
      .catch((error) => {
        // setIsLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    getDataByBranch();
    getActiveModule();
    getInactiveModule();
    getErrorModule();
    getModuleAccessCount();
  }, [branchSelect, range]);

  const branchHandler = (event) => {
    setBranchSelect(event.target.value);
  };
  function clearHandleClick() {
    setBranchSelect("");
    setRange([
      {
        endDate: new Date(),
        startDate: myPastDate,
      }
    ]);
  }
  const datePickerOpen = (event) => {
    setDatePickEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setDatePickEl(null);
  };
  return (
    <div>
      <div className="container pt-2 pb-5 min-height-79">
        <div className="col-12 row header mt-4">
          <div className="col-12 col-md-1">
            <BackButton />
          </div>
          <div className="col-12 col-md-11 row justify-content-end p-0">
            <div className="col-12 col-md-4">
              <TextField id="select1" label="Branch" fullWidth size="medium" sx={{ width: "100%" }} value={branchSelect} select onChange={branchHandler}>
                {branches && branches.map((object, id) => ( <MenuItem value={object.id} key={object.id}>{object.name}</MenuItem> ))}
              </TextField>
            </div>
            <div className="col-12 col-md-4">
              <TextField
                sx={{ width: "auto%" }}
                fullWidth
                id="daterange"
                InputProps={{startAdornment: (<InputAdornment position="start"><IconButton><CalenderIcon /></IconButton></InputAdornment> ),}}
                value={`${format(
                  range[0].startDate,
                  "MMM dd, yyyy"
                )} - ${format(range[0].endDate, "MMM dd, yyyy")}`}
                readOnly
                className="inputBox"
                onClick={datePickerOpen}
              ></TextField>
              <Menu id="settings-menu" anchorEl={datePickEl} open={datePick} onClose={datePickerClose} MenuListProps={{ "aria-labelledby": "settings-button" }}>
                <DateRange onChange={(item) => setRange([item.selection])} editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={range} months={1} direction="horizontal" className="calendarElement"/>
              </Menu>
            </div>
            <div className="col-12 col-md-2 pe-0">
              <Button onClick={clearHandleClick} className="col-12" sx={{ background: theme.palette.gray.gray_5, height: "100%", }} >
                <Typography sx={{ color: theme.palette.gray.gray_1 }}>Clear</Typography>
                <ClearAllIcon sx={{ color: theme.palette.gray.gray_1 }} />
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <div className="col-12 row">
              <div className="col-12 col-md-4  m-0">
                <div className="col-4 w-100 modules">
                  <div className="col-12 row">
                    <div className="col-12 col-md-2 pe-0"><ActiveModule style={{ width: "100%", Height: "30px" }} /></div>
                    <div className="col-12 col-md-10 pe-0">
                      <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>Most active modules</Typography>
                    </div>
                  </div>
                  {active.length>0 ? active.map((object) => {
                    let color = theme.palette.green_success.main;
                    let width1 = (parseInt(object.count) / totalActive) * 100;
                    let width2 = 100 - width1;
                    return (
                      <Fragment key={object.id}>
                        <div className="col-md-12 row">
                          <div className="col-2 d-flex align-items-end">
                            {object.icon_url ?
                              <img src={object.icon_url} alt="icon" style={{ width: "40px", height: "40px", border: "1px solid #E0E0E0", borderRadius: "6px", }} />
                            :
                              <ModuleDark style={{ width: "40px", height: "40px", border: "1px solid #E0E0E0", borderRadius: "6px", }}/>
                              }
                          </div>
                          <div className="col-10">
                            <Typography sx={{ color: theme.palette.lync_black.main, fontWeight: "500 !important", }} >{object.count}</Typography>
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }} >{object.name}</Typography>
                            <div style={{ display: "flex", width: "100%", borderRadius: "2.5px", height: "15px", }}>
                              <span style={{ heigt: "15px !important", background: color, width: width1 + "%", }}/>
                              <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: width2 + "%",}}/>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                  :
                  <div className="col-12 text-center mt-5">
                    <span>No results found</span>
                  </div>}
                </div>
              </div>
              <div className="col-12 col-md-4 m-0">
                <div className="col-4 w-100 modules">
                  <div className="col-12 row">
                    <div className="col-12 col-md-2 pe-0"><InactiveModule style={{ width: "100%", Height: "30px" }} /></div>
                    <div className="col-12 col-md-10 pe-0">
                      <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>Most inactive modules</Typography>
                    </div>
                  </div>
                  {inactive?.length>0 ? inactive.map((object) => {
                    let color = theme.palette.yellow_warning.main;
                    let width1 =(parseInt(object.count) / totalInactive) * 100;
                    let width2 = 100 - width1;
                    return (
                      <Fragment key={object.id} >
                        <div className="col-md-12 row">
                          <div className="col-2 d-flex align-items-end">
                            <img src={object.icon_url} alt="icon" style={{ width: "40px", height: "40px", border: "1px solid #E0E0E0", borderRadius: "6px", }}/>
                          </div>
                          <div className="col-10 pe-0">
                            <Typography sx={{ color: theme.palette.lync_black.main, fontWeight: "500 !important",}}>{object.count}</Typography>
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>{object.name}</Typography>
                            <div style={{ display: "flex", borderRadius: "2.5px", height: "15px", width: "100%",}}>
                              <span style={{ heigt: "15px !important", width: width1 + "%", background: color, }}/>
                              <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: width2 + "%", }}/>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                  :
                  <div className="col-12 text-center mt-5">
                    <span>No results found</span>
                  </div>}
                </div>
              </div>
              <div className="col-12 col-md-4 m-0">
                <div className="col-4 w-100 modules">
                  <div className="col-12 row">
                    <div className="col-12 col-md-2 pe-0"><ErrorModule style={{ width: "100%", Height: "30px" }} /></div>
                    <div className="col-12 col-md-10 pe-0">
                      <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>Most error causing modules</Typography>
                    </div>
                  </div>
                  {errorModule?.length>0 ? errorModule.map((object) => {
                    let color = theme.palette.red_error.main;
                    let width1 = (parseInt(object.count) / totalError) * 100;
                    let width2 = 100 - width1;
                    return (
                      <Fragment key={object.id}>
                        <div className="col-md-12 row">
                          <div className="col-2 d-flex align-items-end">
                            <img src={object.icon_url} alt="icon"style={{ width: "40px", height: "40px", border: "1px solid #E0E0E0", borderRadius: "6px",}}/>
                          </div>
                          <div className="col-10">
                            <Typography sx={{ color: theme.palette.lync_black.main, fontWeight: "500 !important",}}>{object.count}</Typography>
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>{object.name}</Typography>
                            <div style={{display: "flex", height: "15px", borderRadius: "2.5px", width: "100%",}}>
                              <span style={{ heigt: "15px !important", background: color, width: width1 + "%",}}/>
                              <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: width2 + "%",}}/>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                :
                <div className="col-12 text-center mt-5">
                  <span>No results found</span>
                </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5 mb-5 ml-3 row">
          <small className="pl-2" style={{border:"1px solid #E0E0E0"}} />
        </div> 
        <div className="col-12">
          <div>
            <div className="animate__animated animate__fadeIn col-12 row border-bottom pt-3 pb-3">
              <div className="col-12 col-md-2"><Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_3 }}>ID</Typography></div>
              <div className="col-12 col-md-4"><Typography variant="moduleName"sx={{ color: theme.palette.gray.gray_3 }}>Module name</Typography></div>
              <div className="col-12 col-md-3 d-flex justify-content-end pe-0">
                <div className="col-md-2"><AccessCount style={{ width: '100%',}} /></div>
                <div className="col-md-3"><Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_3 }}>Access</Typography></div>
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-end pe-0">
                <div className="col-md-2"><ErrorCount style={{ width: '100%',}}/></div>
                <div className="col-md-3"><Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_3 }}>Errors</Typography></div>
              </div>
            </div>
          </div>
          {moduleAccess.length === 0 &&
            <div>
              <div className="animate__animated animate__fadeIn col-12 row pt-3 pb-3 m-5 mb-5">
                <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">No results found</div>
              </div>
            </div>}
          {moduleAccess && moduleAccess.map((mod) => {
            return (
              <div>
                <div className="animate__animated animate__fadeIn col-12 row border-bottom pt-3 pb-3">
                  <div className="col-12 col-md-2 d-flex align-items-center">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{mod.id}</Typography>
                  </div>
                  <div className="col-12 col-md-4 row d-flex align-items-center">
                    <div className="col-md-4 col-md-1">
                      <ModuleDark style={{ height: "40px", width: '40%',}}/>
                    </div>
                    <div className="col-md-4 col-md-8 ps-0 pe-0">
                      <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{mod.name}</Typography>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{mod.accessCount}</Typography>
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{mod.errorCount}</Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModuleAccess;
