import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import Pagination from "@mui/material/Pagination";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import moment from "moment";
import BackButton from "../components/back";
import {
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import * as FiIcons from "react-icons/fi";
import CalenderIcon from "../components/icons/calenderIcon";
import { useTheme } from "@mui/styles";

const AccessLogs = (props) => {
  const { setIsLoading } = props;
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branchSelect, setBranchSelect] = useState("");
  const [userType, setUserType] = useState([]);
  const [moduleName, setModuleName] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterEl, setFilterEl] = useState(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [moduleFilter, setModuleFilter] = useState("");
  const [datePickEl, setDatePickEl] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const datePick = Boolean(datePickEl);
  const filterMenu = Boolean(filterEl);
  let newDate = new Date();
  let myPastDate = new Date(newDate);
  myPastDate.setDate(myPastDate.getDate() - 14);
  const [range, setRange] = useState([
    {
      startDate: myPastDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // const [userTypeExpanded, setUserTypeExpanded] = useState('userTypeDropdown');
  // const [moduleExpanded, setModuleExpanded] = useState('moduleDropdown');
  async function getDataByBranch() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/branch/list`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getData() {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/user/access-logs?per-page=10`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        page: page,
        branchId: branchSelect,
        search: searchTerm,
        userTypeId: filterTerm,
        moduleId: moduleFilter,
        startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      },
    };
    axios(config)
      .then((res) => {
        setIsLoading(false);
        setData(res.data.data);
        setPageCount(res.data.data._meta.pageCount);
        // console.log(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function getUserType() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/user/type`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((res) => {
        setUserType(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getModuleNames() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/module/list?`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        branch_id: branchSelect,
      },
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
        setModuleName(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getDataByBranch();
    getUserType();
  }, [])
  useEffect(() => {
    getData();
    getModuleNames();

  }, [branchSelect, page, searchTerm, filterTerm, range, moduleFilter]);

  const branchHandler = (event) => {
    setIsDisable(false);
    setBranchSelect(event.target.value);
  };
  function clearHandleClick() {
    setBranchSelect("");
    setFilterTerm("");
    setModuleFilter("");
    setSearchTerm("");
    setPage(1);
    setIsDisable(true);
    setRange([
      {
        endDate: new Date(),
        startDate: myPastDate,
      }
    ]);
  }
  const pageHandler = (event, value) => {
    setPage(value);
  };
  const filterOpen = (event) => {
    setFilterEl(event.currentTarget);
  };
  const filterClose = () => {
    setFilterEl(null);
  };
  const datePickerOpen = (event) => {
    setDatePickEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setDatePickEl(null);
  };
  // const handleUserTypeDropdown = (panel) => (event, newExpanded) => {
  //   setUserTypeExpanded(newExpanded ? panel : false);
  // };
  // const handleModuleDropdown = (panel) => (event, newExpanded) => {
  //   setModuleExpanded(newExpanded ? panel : false);
  // };
  const userTypeFilterHandler = (event) => {
    setFilterTerm(event.target.value);
  }
  const moduleFilterHandler = (event) => {
    setModuleFilter(event.target.value);
  }

  return (
    <div className="min-height-79">
      <div className="container pt-2 pb-4">
        <div className="col-12 row header mt-4">
          <div className="col-12 col-md-1">
            <BackButton />
          </div>
          <div className="col-12 col-md-11 row m-0 p-0 justify-content-end">
            <div className="col-12 col-md-3">
              <TextField
                fullWidth
                label="Search"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                value={searchTerm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={filterOpen}>
                        <FiIcons.FiFilter />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <Menu
                id="settings-menu"
                anchorEl={filterEl}
                open={filterMenu}
                onClose={filterClose}
                MenuListProps={{ "aria-labelledby": "settings-button" }}
              >
                <div className="container" style={{ width: '400px' }}>
                  <div className="col-12 row p-3 ps-0">
                    <Typography
                      variant="moduleName"
                      sx={{
                        color: theme.palette.gray.gray_1,
                        fontWeight: '600 !important'
                      }}>
                      Filters
                    </Typography>
                  </div>
                  <div className="shadow-none border-article rounded">
                    <TextField
                      label='User type'
                      select
                      fullWidth
                      value={filterTerm}
                      onChange={userTypeFilterHandler}
                    >
                      {userType &&
                        userType.map((obj) => (
                          <MenuItem
                            className="border-top p-3"
                            value={obj.key}
                            key={obj.key}>
                            <Typography sx={{ color: theme.palette.gray.gray_2 }}>
                              {obj.value}
                            </Typography>
                          </MenuItem>
                        ))}
                    </TextField>
                    </div>
                  <div className="shadow-none border-article rounded mt-3 mb-3">
                    {isDisable &&
                      <Typography variant="moduleName" sx={{ color: theme.palette.red_error.main }}>
                        Select a branch</Typography>}
                    <TextField
                      label='Module'
                      select
                      value={moduleFilter}
                      disabled={isDisable}
                      onChange={moduleFilterHandler}
                      fullWidth
                    >
                      {moduleName && moduleName.map((ob) => (
                        <MenuItem
                          className="border-top p-3"
                          value={ob.id}
                          key={ob.id}>
                          <Typography sx={{ color: theme.palette.gray.gray_2 }}>
                            {ob.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                   </div>
              </Menu>
            </div>
            <div className="col-12 col-md-2">
              <TextField
                id="select1"
                label="Branch"
                // select
                size="medium"
                sx={{ width: "100%" }}
                value={branchSelect}
                select
                onChange={branchHandler}
              >
                {branches &&
                  branches.map((object, id) => (
                    <MenuItem value={object.id} key={object.id}>{object.name}</MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-12 col-md-4">
              <TextField
                id="daterange"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <CalenderIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // value={`${format(range[0].startDate, "MMM dd, yyyy")} - ${format(range[0].endDate, "MMM dd, yyyy")}`}
                readOnly
                value={`${format(range[0].startDate, "MMM dd, yyyy")} - ${format(
                  range[0].endDate,
                  "MMM dd, yyyy"
                )}`}
                className="inputBox"
                onClick={datePickerOpen}
              // onClick={ () => setOpen(open => !open) }
              ></TextField>
              <Menu
                id="settings-menu"
                anchorEl={datePickEl}
                open={datePick}
                onClose={datePickerClose}
                MenuListProps={{ "aria-labelledby": "settings-button" }}
              >
                <DateRange
                  onChange={(item) => setRange([item.selection])}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  direction="horizontal"
                  className="calendarElement"
                />
              </Menu>
            </div>
            <div className="col-12 col-md-2">
              <Button
                onClick={clearHandleClick}
                className="col-12"
                sx={{
                  background: theme.palette.gray.gray_5,
                  height: "100%",
                }}
              >
                <Typography sx={{ color: theme.palette.gray.gray_1 }}>
                  Clear
                </Typography>
                <ClearAllIcon sx={{ color: theme.palette.gray.gray_1 }} />
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div>
            <div className="animate__animated animate__fadeIn col-12 row border-bottom pt-3 pb-3">
              <div className="col-12 col-md-1 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>ID</Typography>
              </div>
              <div className="col-12 col-md-3 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>User</Typography>
              </div>
              <div className="col-12 col-md-1 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>User type</Typography>
              </div>
              <div className="col-12 col-md-2 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>Branch</Typography>
              </div>
              <div className="col-12 col-md-3 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>Access type</Typography>
              </div>
              <div className="col-12 col-md-2 m-0 p-0">
                <Typography sx={{ color: theme.palette.gray.gray_3 }}>Date and time</Typography>
              </div>
            </div>
          </div>
          {data.items && data.items.length === 0 &&
            <div>
              <div className="animate__animated animate__fadeIn col-12 row pt-3 pb-3 m-0">
                <div className="col-12 text-center mt-5">No results found</div>
              </div>
            </div>}
          {data.items && data.items.map((obj, id) => {
            let moduleName = "";
            if (obj.module !== null) {
              moduleName = "(" + obj.module + ")";
            }
            return (
              <div key={id}>
                <div className="animate__animated animate__fadeIn col-12 row border-bottom pt-3 pb-3" key={id}>
                  <div className="col-12 col-md-1 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{obj.id}</Typography>
                  </div>
                  <div className="col-12 col-md-3 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{obj.user}</Typography>
                  </div>
                  <div className="col-12 col-md-1 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{obj.user_type_name}</Typography>
                  </div>
                  <div className="col-12 col-md-2 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>{obj.branch_name}</Typography>
                  </div>
                  <div className="col-12 col-md-3 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1, textTransform: 'capitalize !important' }}>{obj.event_name} {moduleName}</Typography>
                  </div>
                  <div className="col-12 col-md-2 m-0 p-0">
                    <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_1 }}>
                      {moment(new Date(obj.created_at * 1000)).format("MMM DD, YYYY ")}at{moment(new Date(obj.created_at * 1000)).format(" hh:mm a")}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-12 d-flex justify-content-center pt-3 mt-5 pb-5">
          <Pagination
            count={pageCount}
            page={page}
            onChange={pageHandler}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default AccessLogs;
