import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Logo } from "../../assets/closeIcon.svg";

function CloseIcon(props) {
    const style = { 
        left: "23.48%",
        right: "23.48%",
        top: "23.48%",
        bottom: "23.48%",
        backGround: "#D9D8DD"}

    return (
      <SvgIcon {...props}>
       <Logo style={style}/>
      </SvgIcon>
    );
  }

  export default CloseIcon;