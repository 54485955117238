import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
const theme = createTheme({
  palette: {
    primary: {
      main: '#20123A',
    },
    secondary: {
      main: '#1A122B',
    },
    lync_black: {
      main: '#25282B',
    },
    gray: {
      gray_1: '#333333',
      gray_2: '#4F4F4F',
      gray_3: '#828282',
      gray_5: '#E0E0E0',
      gray_6: '#F2F2F2',
    },
    red_error: {
      main : '#FB4E4E',
    },
    yellow_warning: {
      main: '#F6A609',
    },
    green_success: {
      main: '#2AC769',
    },
    blue_default: {
      main: '#2A6BEA',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto !important',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: "unset !important",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        pageTitles: {
          fontWeight: '700 !important',
          fontSize: '1.6rem !important',
          lineHeight: '28px !important',
          textTransform: 'capitalize !important',
        },
        bodyDefault: {
          fontWeight: '600 !important',
          lineHeight: '19px !important',
        },
        moduleTitle: {
          fontSize: '1.2rem !important',
          fontWeight: '500 !important',
          lineHeight: '21px', 
        },
        number: {
          fontWeight: '500 !important',
          fontSize: '2rem !important',
          lineHeight: '35px !important',
        },
        progress: {
          fontSize: '1.333em !important',
          fontWeight: '500 !important',
          lineHeight: '23px !important',
        },
        moduleName: {
          fontSize: '0.933rem !important',
          lineHeight: '16px !important',
        }
      },
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  {/* <React.StrictMode> */}
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  {/* </React.StrictMode> */}
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
