import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Logo } from "../../assets/ico_module_inactive.svg";

function InactiveModule(props) {
    const style = { left: "8.33%",
        right: "8.33%",
        top: "4.17%",
        bottom: "12.5%",
        
        /* form-stroke- outline */
        backGround: "#D9D9D9"}

    return (
      <SvgIcon {...props}>
       <Logo style={style}/>
      </SvgIcon>
    );
  }

  export default InactiveModule;