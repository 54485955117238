import React, { useState, Fragment } from "react";
import CalenderIcon from "../components/icons/calenderIcon";
import format from "date-fns/format";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  Typography,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Menu,
} from "@mui/material";
import { DateRange } from "react-date-range";
import { useTheme } from "@mui/styles";
import axios from "axios";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useEffect } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import BackButton from "../components/back";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const config = {
  type: "line",
  responsive: true,
  scales: {
    yAxes: {
      title: {
        display: true,
        text: "Number of logins per day",
      },
    },
    xAxes: {
      grid: {
        display: false,
      },
    },
  },
  interaction: {
    mode: "nearest",
    // axis: 'x',
    intersect: false,
  },
  animation: {
    duration: 2000,
  },
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "login per day",
    },
  },
};
const UserAccess = (props) => {
  const { setIsLoading } = props;
  const theme = useTheme();
  let newDate = new Date();
  let myPastDate = new Date(newDate);
  myPastDate.setDate(myPastDate.getDate() - 6);
  const [range, setRange] = useState([
    {
      startDate: myPastDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState("");
  const [loginCount, setLoginCount] = useState([]);
  const [data, setData] = useState({
    labels: "",
    datasets: [{}],
  });

  const [datePickEl, setDatePickEl] = useState(null);
  const datePick = Boolean(datePickEl);

  const handleChange = (event) => {
    setBranchSelected(event.target.value);
  };

  async function getDataByBranch() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/branch/list`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getLoginCount() {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/access/get-login-count?`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        date: moment(range[0].endDate).format("YYYY-MM-DD"),
        week: moment(range[0].startDate).format("YYYY-MM-DD")
      }
    };
    axios(config)
      .then((res) => {
        setIsLoaded(true);
        setIsLoading(false);
        setLoginCount(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getDataByBranch();
    async function getchartData() {
      isLoaded===true && setIsLoading(true);
      const dataSet1 = [];
      const dataSet2 = [];
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/module/login-count?`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          branchId: branchSelected,
          startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
          endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
        },
      };
      axios(config)
        .then((res) => {
          isLoaded===true && setIsLoading(false);
          setData(res.data.data);
          for (const val of res.data.data.loginCount) {
            let d = new Date(val.date * 1000);
            dataSet1.push(moment(d).format("DD-MMM"));
            dataSet2.push(val.count);
          }
          setData({
            labels: dataSet1,
            datasets: [
              {
                label: "login",
                data: dataSet2,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgb(53, 162, 235)",
                // tension: 0.4,
                pointRadius: 4,
                pointHoverRadius: 10,
              },
            ],
          });

        })
        .catch((error) => {
          console.error(error);
        });
    }
    getchartData();
  }, [branchSelected, range]);
  useEffect(() => {
    getLoginCount();

  }, [])

  const datePickerOpen = (event) => {
    setDatePickEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setDatePickEl(null);
  };
  function clearHandleClick() {
    setBranchSelected("");
    setRange([
      {
        endDate: new Date(),
        startDate: myPastDate,
      }
    ]);
  }
  return (
    <div className="container pt-2 pb-5 min-height-79">
      <div className="col-12 row header mt-4 pt-4">
        <div className="col-12 col-md-1">
          <BackButton />
        </div>
      </div>
      <div className="mt-5">
        <div>
          <div className="col-md-12 row">
            <div className="col-12 col-md-4 m-0 mb-3 mb-md-0">
              {loginCount.uniqueLoginCount &&
                <div className="col-4 w-100 modules">
                  <Fragment>
                    <div className="col-12 row">
                      <div className="col-12 col-md-5">
                        <Typography variant="number" sx={{ color: theme.palette.gray.gray_1 }}>{loginCount.uniqueLoginCount.totalCount}</Typography>
                      </div>
                      <div className="col-12 col-md-10">
                        <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>Unique login today</Typography>
                      </div>
                    </div>
                    {loginCount.uniqueLoginCount && loginCount.uniqueLoginCount.uniqueLoginCount.map((ob, id) => {
                      let color = '';
                      let width = (ob.count / loginCount.uniqueLoginCount.totalCount) * 100;
                      if (ob.user_type === "teacher") {
                        color = theme.palette.red_error.main;
                      }
                      else if (ob.user_type === 'student') {
                        color = theme.palette.blue_default.main;
                      }
                      else if (ob.user_type === 'parents') {
                        color = theme.palette.yellow_warning.main;
                      }
                      return (
                        <div className="col-12 row" key={id}>
                          <div className="col-md-12">
                            <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>{ob.count}</Typography>
                          </div>
                          <div className="col-md-12">
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>{ob.user_type}</Typography>
                          </div>
                          <div className="col-md-12 d-flex"
                            style={{ borderRadius: "2.5px", height: "15px",}}>
                            <span style={{ heigt: "15px !important", background: color, width: width + "%", }}/>
                            <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: 100 - width + "%", }}/>
                          </div>
                        </div>
                      )
                    })}
                  </Fragment>
                </div>
              }
            </div>

            <div className="col-12 col-md-4 m-0 mb-3 mb-md-0">
              {loginCount.loginCount &&
                <div className="col-4 w-100 modules">
                  <Fragment>
                    <div className="col-12 row" >
                      <div className="col-12 col-md-5">
                        <Typography variant="number" sx={{ color: theme.palette.gray.gray_1 }}>{loginCount.loginCount.totalCount}</Typography>
                      </div>
                      <div className="col-12 col-md-10">
                        <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>Login today</Typography>
                      </div>
                    </div>
                    {loginCount.loginCount.loginCount.map((ob) => {
                      let color = '';
                      let width = (ob.count / loginCount.loginCount.totalCount) * 100;
                      if (ob.user_type === "teacher") {
                        color = theme.palette.red_error.main;
                      }
                      else if (ob.user_type === 'student') {
                        color = theme.palette.blue_default.main;
                      }
                      else if (ob.user_type === 'parents') {
                        color = theme.palette.yellow_warning.main;
                      }
                      return (
                        <div className="col-12 row" key={ob.id}>
                          <div className="col-md-12">
                            <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>{ob.count} </Typography>
                          </div>
                          <div className="col-md-12">
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>{ob.user_type}</Typography>
                          </div>
                          <div className="col-md-12 d-flex" style={{ borderRadius: "2.5px", height: "15px", }}>
                            <span style={{ heigt: "15px !important", background: color, width: width + "%",}} />
                            <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: 100 - width + "%", }}/>
                          </div>
                        </div>
                      )
                    })}
                  </Fragment>
                </div>
              }
            </div>

            <div className="col-12 col-md-4 m-0">
              {loginCount.weeklyLoginCount &&
                <div className="col-4 w-100 modules">
                  <Fragment>
                    <div className="col-12 row" >
                      <div className="col-12 col-md-5">
                        <Typography variant="number" sx={{ color: theme.palette.gray.gray_1 }}>{loginCount.weeklyLoginCount.totalCount}</Typography>
                      </div>
                      <div className="col-12 col-md-10">
                        <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>Login this week</Typography>
                      </div>
                    </div>
                    {loginCount.weeklyLoginCount && loginCount.weeklyLoginCount.weeklyLoginCount.map((ob) => {
                      let color = '';
                      let width = (ob.count / loginCount.weeklyLoginCount.totalCount) * 100;
                      if (ob.user_type === "teacher") {
                        color = theme.palette.red_error.main;
                      }
                      else if (ob.user_type === 'student') {
                        color = theme.palette.blue_default.main;
                      }
                      else if (ob.user_type === 'parents') {
                        color = theme.palette.yellow_warning.main;
                      }
                      return (
                        <div className="col-12 row" key={ob.id}>
                          <div className="col-md-12">
                            <Typography variant="progress" sx={{ color: theme.palette.gray.gray_1 }}>{ob.count}</Typography>
                          </div>
                          <div className="col-md-12">
                            <Typography variant="moduleName" sx={{ color: theme.palette.gray.gray_2 }}>{ob.user_type}</Typography>
                          </div>
                          <div className="col-md-12 d-flex" style={{ borderRadius: "2.5px", height: "15px",}}>
                            <span style={{ heigt: "15px !important", background: color, width: width + "%",}}/>
                            <span style={{ heigt: "15px !important", background: theme.palette.gray.gray_6, width: 100 - width + "%",}}/>
                          </div>
                        </div>
                      )
                    })}
                  </Fragment>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5 mb-5 ml-3 row">
        <small className="pl-2" style={{ border: "1px solid #E0E0E0" }} />
      </div>
      <div className="col-12 row header mb-5">
        <div className="col-12 col-md-3">
          <Typography sx={{ color: theme.palette.gray.gray_1, fontWeight: '700 !important' }}>Users per day</Typography>
        </div>
        <div className="col-12 col-md-9 row justify-content-end p-0 gap-3">
          <div className="col-12 col-md-3 p-0">
            <TextField id="select1" label="Branch" size="medium" sx={{ width: "100%" }} value={branchSelected} select onChange={handleChange}>
              {branches && branches.map((object, i) => ( <MenuItem value={object.id} key={i}>{object.name}</MenuItem>))}
            </TextField>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-end p-0">
            <TextField
              id="daterange"
              InputProps={{ startAdornment: ( <InputAdornment position="start"><IconButton><CalenderIcon /></IconButton></InputAdornment>),}}
              value={`${format(range[0].startDate, "MMM dd, yyyy")} - ${format(range[0].endDate, "MMM dd, yyyy" )}`}
              readOnly
              className="inputBox"
              onClick={datePickerOpen}
            ></TextField>
            <Menu id="settings-menu" anchorEl={datePickEl} open={datePick} onClose={datePickerClose} MenuListProps={{ "aria-labelledby": "settings-button" }}>
              <DateRange onChange={(item) => setRange([item.selection])} editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={range} months={1} direction="horizontal" className="calendarElement"/>
            </Menu>
          </div>
          <div className="col-12 col-md-2 p-0">
            <Button onClick={clearHandleClick} className="col-12" sx={{ background: theme.palette.gray.gray_5, height: "100%",}}>
              <Typography sx={{ color: theme.palette.gray.gray_1 }}>Clear</Typography><ClearAllIcon sx={{ color: theme.palette.gray.gray_1 }} />
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="col-md-7 row">{data && <Line data={data} options={config}></Line>}</div>
        </div>
      </div>
    </div>
  );
};

export default UserAccess;
