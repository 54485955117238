import React from 'react';
import './navBar.css';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import lyncIcon from '.././assets/ico_dash.png';
import styled from 'styled-components';
import axios from'axios';
import profilePic from '../assets/profile.svg';
// import { Menu, MenuItem } from '@mui/material';

export const NavLink = styled(Link)`
  color: #333333;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &.active {
    background: linear-gradient(90deg, #FF1E48 0%, #FF731A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    color : #FF731A;
  }
`;
const Header = (isLogin) => {
    let navigate = useNavigate();
  const [profileData, setProfileData] = React.useState('');


    async function fetchUserDetails(){
        if (localStorage.getItem("token")) {
            var config = {
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/user/user-data`,
                };
                axios(config)
                .then((res) => {
                    setProfileData(res.data.data);
                    
                })
                .catch((error) => {
                    console.error(error)
                      })}
      }

      const logoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("token");
        navigate("/");
        };

      React.useEffect(() => {
          fetchUserDetails()
      }, [isLogin]);
    
    
    return(
        <div className="navbar_1 navbar col-12 ps-3 pb-4 pt-4">
            <div className="container">
            <div className="navbarTools col-9">
                <img src={lyncIcon} width='auto' alt='lync icon' />
                    {/* <MenuItem component={Link} to={'/'} open={true}>Users</MenuItem>
                    <MenuItem component={Link} to={'/analytics/user-access'} open={true}>User Access</MenuItem> */}
                
                <NavLink to="/analytics/users">Users</NavLink>
                <NavLink to="/analytics/user-access">User Access</NavLink>
                <NavLink to="/analytics/module-access">Module Access</NavLink>
                <NavLink to="/analytics/access-logs">Access logs</NavLink>

            </div>
            {/* <div className="col-3"> */}
                <div className="ps-3 d-none d-lg-inline" id="open-menu">
                    {localStorage.getItem("token") &&
                    <div className="dropdown profile-dropdown cursor-pointer">
                        <span className="dropdown-toggle" id="profile-dropdown" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className="profile-collection ms-2" src={profilePic} alt={profileData?.first_name} />
                        <span className="bg-text-secondary ms-2">{profileData && profileData?.first_name + ' ' + profileData?.last_name}</span>
                        {/* <Image className="profile-collection ms-2" src={process.env.REACT_APP_BASE_URL + "/" + profileData?.image} alt={profileData?.first_name} onError={replaceImage} /> */}
                        </span>
                            <ul className="dropdown-menu p-0 m-0 mt-2" aria-labelledby="profile-dropdown" data-bs-popper="static">
                                <li>
                                    <NavLink className="dropdown-item p-2 ps-3" to="/login" onClick={(e) => logoutHandler(e)}>{'Logout'}</NavLink>
                                </li>
                            </ul>
                    </div>
                   }
                </div>
            {/* </div> */}
            </div>
        </div>
    )
}

export default Header;