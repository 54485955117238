import './App.css';
import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import UserAccess from './pages/userAccess';
import User from './pages/users';
// import ProgressBarUsers from './pages/progressUser';
import LoadingOverlay from "react-loading-overlay-ts";
import Header from './components/navBar';
// import Navbar from './components/navbar/navBar';
import ModuleAccess from './pages/moduleAccess';
import AccessLogs from './pages/accessLogs';
import Login from './pages/login';
import AxiosInterceptor from './helpers/axiosInterceptor';
import ProtectedRoutes from './helpers/protectedRoutes';
function App() {
  AxiosInterceptor();

  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false); 
  const [isLogin, setIsLogin] = useState(false); 

  return (
    <div className="App">
       {location.pathname!=='/' &&  <Header isLogin={isLogin} />}
        {/* <Navbar /> */}
        <Routes>
            <Route exact path="/" strict element={<LoadingOverlay active={isLoading} spinner><Login isLoading={isLoading} setIsLoading={setIsLoading} setIsLogin={setIsLogin}/></LoadingOverlay>} />
            <Route exact path="/analytics/users" strict element={<ProtectedRoutes><LoadingOverlay active={isLoading} spinner><User setIsLoading={setIsLoading}/></LoadingOverlay></ProtectedRoutes>} />
            <Route exact path="/analytics/user-access" element={<ProtectedRoutes><LoadingOverlay active={isLoading} spinner><UserAccess setIsLoading={setIsLoading}/></LoadingOverlay></ProtectedRoutes>} />
            <Route exact path="/analytics/module-access" element={<ProtectedRoutes><LoadingOverlay active={isLoading} spinner><ModuleAccess setIsLoading={setIsLoading} /></LoadingOverlay></ProtectedRoutes>} />
            <Route exact path="/analytics/access-logs" element={<ProtectedRoutes><LoadingOverlay active={isLoading} spinner><AccessLogs setIsLoading={setIsLoading} /></LoadingOverlay></ProtectedRoutes>} />
        </Routes>
    </div>
  );
}

export default App;
