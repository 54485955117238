import React, {Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import * as AiIcons from 'react-icons/ai';
import { Typography } from '@mui/material';
import { useTheme } from "@mui/styles";

const useStyles = makeStyles(() => ({
    backIcon: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      gap: "1vh",
      height: "0.5rem",
      cursor: "pointer",
      flex: "none",
      order: '0',
      flexGrow: '0',
    },
  }));

const BackButton = () => {
    const {backIcon} = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const style = { left: "16.67%",
        right: "16.67%",
        top: "17.59%",
        bottom: "17.59",
        backGround: "rgba(0, 0, 0, 0.85)"}
    return(
        <Fragment>
            <div onClick={()=>navigate(-1)} style={{ textDecoration: 'none'}} className="p-0">
                <div className={backIcon} >
                    <AiIcons.AiOutlineArrowLeft style={style}/>
                    <Typography sx={{color: theme.palette.gray.gray_1, fontWeight: '600 !important'}}>Back</Typography>
                </div>
            </div>
        </Fragment>
    )
       
    
}

export default BackButton;